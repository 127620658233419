import * as React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import pl from "@fullcalendar/core/locales/pl";
import { Link } from "wouter";
import { GlobalStyles, useTheme } from "@mui/material";
import { useGetDeadlines } from "../../api/deadline";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/lab";

function renderEventContent(eventInfo: any) {
  return (
    <>
      <Link
        to={`/fleet/${eventInfo.event.id}`}
        style={{ color: "white", textDecoration: "none" }}
      >
        {eventInfo.event.title}
      </Link>
    </>
  );
}

export default function Calendar() {
  const { data, isLoading } = useGetDeadlines();
  const theme = useTheme();
  const events = (data || []).map((deadline) => ({
    title: `${deadline.name} - ${deadline.fleetItem.registrationNumber}`,
    start: deadline.date,
    allDay: true,
    id: `${deadline.fleetItemId}`,
  }));
  return (
    <div data-testid={"calendar"}>
      <GlobalStyles
        styles={{
          ":root": {
            "--fc-border-color": theme.palette.grey.A700,
            "--fc-today-bg-color": "rgba(124, 179, 66, 0.16)",
          },
        }}
      />
      {isLoading && (
        <>
          <Skeleton sx={{ py: 0 }} width={250} height={50} />
          {Array.from(Array(4).keys()).map(() => (
            <Grid container columnSpacing={2}>
              {Array.from(Array(7).keys()).map((i) => (
                <Grid sx={{ height: "100px" }} item xs={12 / 7} key={i + 10}>
                  <Skeleton key={i} sx={{ py: 0 }} height={150} />
                </Grid>
              ))}
            </Grid>
          ))}
        </>
      )}
      {!isLoading && (
        <FullCalendar
          plugins={[dayGridPlugin]}
          aspectRatio={1.75}
          locale={pl}
          initialView="dayGridMonth"
          eventBackgroundColor={theme.palette.primary.main}
          eventBorderColor={theme.palette.primary.main}
          eventTextColor={"white"}
          nowIndicatorContent={"Dzisiaj"}
          events={events}
          eventContent={renderEventContent}
        />
      )}
    </div>
  );
}
