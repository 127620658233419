import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as locales from "@mui/material/locale";

interface ThemeProviderProps {
  children: React.ReactNode;
}

const initialTheme = createTheme(
  {
    palette: {
      background: {
        default: "#2a2d35",
        paper: "#2a2d35",
      },
      primary: {
        main: "#7CB342",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#fff",
      },
      secondary: {
        main: "#e51148",
        contrastText: "#fff",
        // dark: will be calculated from palette.secondary.main,
      },
      mode: "dark",
    },
    typography: {
      fontFamily: "'Fira Sans', 'Cantarell', 'Droid Sans', 'Helvetica Neue'",
      h5: {
        fontWeight: 400,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  },
  locales["plPL"],
);

export const theme = {
  ...initialTheme,
  components: {
    // MuiDrawer: {
    //     styleOverrides: {
    //         paper: {
    //             backgroundColor: '#081627',
    //         },
    //     },
    // },
    MuiButton: {
      styleOverrides: {
        // root: {
        //     fontWeight: 400,
        // },
        contained: {
          boxShadow: "none",
          borderRadius: "5px",
        },
        outlined: {
          boxShadow: "none",
          borderRadius: "5px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
          backgroundColor: initialTheme.palette.background.paper,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: initialTheme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    // MuiDivider: {
    //     styleOverrides: {
    //         root: {
    //             backgroundColor: 'rgb(255,255,255,0.15)',
    //         },
    //     },
    // },
    // MuiListItemButton: {
    //     styleOverrides: {
    //         root: {
    //             '&.Mui-selected': {
    //                 color: '#7cb342',
    //             },
    //         },
    //     },
    // },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontWeight: 100,
        },
        primary: {
          fontSize: 14,
          fontWeight: 400,
        },
      },
    },
    // MuiListItemIcon: {
    //     styleOverrides: {
    //         root: {
    //             color: 'inherit',
    //             minWidth: 'auto',
    //             marginRight: theme.spacing(2),
    //             '& svg': {
    //                 fontSize: 20,
    //             },
    //         },
    //     },
    // },
    // MuiAvatar: {
    //     styleOverrides: {
    //         root: {
    //             width: 32,
    //             height: 32,
    //         },
    //     },
    // },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "rgba(68, 70, 79, 0.5)",
        },
      },
    },
  },
};
export const ThemeProviderWrapper = ({ children }: ThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
