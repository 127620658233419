import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Driver,
  FleetItem,
  useDeleteFleetItem,
  useGetFleetItem,
} from "../../../../api/fleet";
import Typography from "@mui/material/Typography";
import { Card, CardContent, List } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import DeadlineButton from "../DeadlineButton";
import { Skeleton } from "@mui/lab";
import { DATE_FORMAT } from "../../../../constants/date";
import AttachmentsList from "../AttachmentsList";
import { parseHistoryLogDescription } from "../../../../utils/parseHistoryEvent";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppState } from "../../../../contexts/AppState";
import { useLocation } from "wouter";
import AddFleetItem from "../AddFleetItem";
import AdditionalInfo from "../AdditionalInfo";
import ServiceLogList from "../ServiceLogList";

interface Props {
  itemId?: string;
}

const listItem = { mx: 0, px: 0 };

const getDetailsList = (item: FleetItem) => [
  { label: "Marka i model", value: `${item.make} ${item.model}` },
  { label: "Numer rejestracyjny", value: `${item.registrationNumber}` },
  { label: "Rok produkcji", value: item.year ? item.year : "-" },
  { label: "Przebieg", value: item.mileage ? `${item.mileage} km` : "-" },
  // { label: "Dodatkowe informacje", value: `${item.additionalInfo || "-"}` },
];

const getDriver = ({ driver }: { driver: Driver }) => [
  { label: "Imię nazwisko", value: driver.name || "-" },
  { label: "Numer telefonu", value: driver.phoneNumber || "-" },
];

export default function FleetItemDetails({ itemId }: Props) {
  const { setSnackbar } = useAppState();
  const [editModalOpened, setEditModalOpened] = useState<boolean>(false);
  const [, setLocation] = useLocation();
  const { data: itemDetails, isLoading } = useGetFleetItem(Number(itemId));
  const {
    mutate,
    isLoading: isDeleting,
    isError: isDeleteError,
    error: deleteError,
    isSuccess: isDeleteSuccess,
  } = useDeleteFleetItem();
  const showLoader = isLoading;

  const handleDelete = () => {
    mutate(Number(itemId));
  };
  const handleEdit = () => {
    setEditModalOpened(true);
  };
  useEffect(() => {
    if (isDeleteSuccess) {
      setSnackbar({
        type: "success",
        message: "Pojazd został usunięty!",
      });
      setLocation("/fleet");
    }
  }, [isDeleteSuccess, setSnackbar, setLocation]);

  useEffect(() => {
    if (isDeleteError) {
      setSnackbar({
        type: "error",
        message: deleteError?.message || "Wystąpił błąd...",
      });
    }
  }, [isDeleteError, deleteError, setSnackbar]);

  if (!itemDetails && !isLoading) {
    return <Typography variant={"h5"}>Nie znaleziono pojazdu :(</Typography>;
  }

  return (
    <>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {itemDetails && editModalOpened && (
          <AddFleetItem
            open={editModalOpened}
            itemToEditId={Number(itemId)}
            onClose={() => setEditModalOpened(false)}
            itemToEdit={itemDetails}
          />
        )}
        <Grid item xs={6}>
          <Typography variant={"h5"}>
            {showLoader && <Skeleton />}
            {!showLoader && itemDetails && (
              <>
                {itemDetails.make} {itemDetails.model} (
                {itemDetails.registrationNumber})
              </>
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <LoadingButton
            color={"primary"}
            variant={"contained"}
            sx={{ mr: 2 }}
            loading={isDeleting || showLoader}
            onClick={handleEdit}
          >
            Edytuj pojazd
          </LoadingButton>
          <LoadingButton
            color={"secondary"}
            variant={"contained"}
            loading={isDeleting || showLoader}
            onClick={handleDelete}
          >
            Usuń pojazd
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 16 }}
                color="text.primary"
                gutterBottom
              >
                Szczegóły pojazdu
              </Typography>
              {showLoader &&
                Array.from(Array(7).keys()).map((i) => (
                  <Skeleton key={i} height={40} />
                ))}
              {!showLoader && itemDetails && (
                <List dense={true}>
                  {getDetailsList(itemDetails).map(({ label, value }) => (
                    <ListItem sx={listItem} key={label}>
                      <ListItemText secondary={value} primary={label} />
                    </ListItem>
                  ))}
                </List>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            {itemDetails && itemDetails.drivers[0] && (
              <CardContent>
                <Typography
                  sx={{ fontSize: 16 }}
                  color="text.primary"
                  gutterBottom
                >
                  Kierowca przypisany do pojazdu
                </Typography>
                <List dense={true}>
                  {getDriver(itemDetails.drivers[0]).map(({ label, value }) => (
                    <ListItem sx={listItem} key={label}>
                      <ListItemText secondary={value} primary={label} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 16 }}
                color="text.primary"
                gutterBottom
              >
                Terminy
              </Typography>
              <List dense={true}>
                {showLoader &&
                  Array.from(Array(7).keys()).map((i) => (
                    <ListItem sx={listItem} alignItems={"center"} key={`loader-${i}`}>
                      <Grid container columnSpacing={2}>
                        {Array.from(Array(4).keys()).map((k) => (
                          <Grid item key={k + 11} xs={3}>
                            <Skeleton key={i} height={40} />
                          </Grid>
                        ))}
                      </Grid>
                    </ListItem>
                  ))}
                {!showLoader &&
                  itemDetails &&
                  itemDetails.deadlines.map(({ id, name, date }) => (
                    <React.Fragment key={name}>
                      <ListItem sx={listItem} alignItems={"center"} key={name}>
                        <Grid container columnSpacing={2} alignItems={"center"}>
                          <Grid item xs={3}>
                            <ListItemText
                              primary={"Typ terminu"}
                              secondary={name}
                            />
                          </Grid>
                          <Grid item xs={2.5}>
                            <ListItemText
                              primary={"Data"}
                              secondary={dayjs(date).format("DD/MM/YYYY")}
                            />
                          </Grid>
                          <Grid item xs={2.5}>
                            <ListItemText
                              primary={"Ile do końca"}
                              secondary={dayjs(date).from(new Date())}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{ py: 1.5 }}
                            alignItems={"center"}
                          >
                            <DeadlineButton deadline={{ id, name, date }} />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
              </List>
            </CardContent>
          </Card>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} xl={6} order={{ xs: 2, xl: 1 }}>
              <Card sx={{ mt: 2, maxHeight: 600, overflowY: 'scroll' }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 16 }}
                    color="text.primary"
                    gutterBottom
                  >
                    Historia edycji
                  </Typography>
                  <List dense={true}>
                    {showLoader &&
                      Array.from(Array(7).keys()).map((i) => (
                        <Skeleton key={i} height={60} />
                      ))}
                    {!showLoader &&
                      itemDetails &&
                      (itemDetails.historyLog || []).map(
                        ({ description, createdBy, createdAt }, index) => (
                          <ListItem sx={listItem} key={description + index}>
                            <ListItemText
                              secondary={parseHistoryLogDescription(
                                description,
                                createdBy,
                              )}
                              primary={dayjs(createdAt).format(DATE_FORMAT)}
                            />
                          </ListItem>
                        ),
                      )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} xl={6} order={{ xs: 1, xl: 2 }}>
              {itemDetails && itemId && (
                  <AdditionalInfo
                      initialValue={itemDetails.additionalInfo}
                      itemId={itemId}
                  />
              )}
              {itemDetails && itemId && (
                  <ServiceLogList
                      fleetItemId={itemId}
                      serviceLog={(itemDetails || {}).serviceLog || []}
                      showLoader={showLoader}
                  />
              )}
              {itemId && (
                <AttachmentsList
                  fleetItemId={itemId}
                  attachments={(itemDetails || {}).attachments || []}
                  showLoader={showLoader}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
