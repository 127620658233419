import dayjs from "dayjs";

export const options = [
  "Przedłuż o rok",
  "Przedłuż o 2 lata",
  "Przedłuż o 3 lata",
  "Wybierz datę",
];

export const CUSTOM_DATE_INDEX = 3;

export const getDate = (
  selectedIndex: number,
  currentDeadlineDate: Date,
): Date => {
  if (selectedIndex === 0) {
    return dayjs(currentDeadlineDate).add(1, "year").toDate();
  }
  if (selectedIndex === 1) {
    return dayjs(currentDeadlineDate).add(2, "year").toDate();
  }
  if (selectedIndex === 2) {
    return dayjs(currentDeadlineDate).add(3, "year").toDate();
  }
  return currentDeadlineDate;
};
