import * as React from "react";
import Grid from "@mui/material/Grid";
import { Alert } from "@mui/material";
import EventsList from "./components/EventsList";
import HistoryList from "./components/HistoryList";
import { useGetDeadlines } from "../../api/deadline";
import { Skeleton } from "@mui/lab";
import {useAppState} from "../../contexts/AppState";

const getLabel = (deadlineNumber: number): string => {
  if (deadlineNumber === 1) {
    return "termin traci";
  }
  if (deadlineNumber > 1 && deadlineNumber < 5) {
    return "terminy tracą";
  }
  return "terminów traci";
};
export default function Dashboard() {
  const { data, isSuccess, isLoading } = useGetDeadlines(30);
  const {user} = useAppState();
  const greetings = user ? `Cześć ${user.firstName}` : 'Witaj';
  return (
    <Grid container spacing={2}>
      {isSuccess && (
        <Grid item xs={12}>
          {isLoading && <Skeleton height={80} />}
          {!!data.length && (
            <Alert severity="warning" sx={{ mt: -2, mb: 2 }}>
              {greetings} , w ciągu kolejnych 30 dni <strong>{data.length}</strong>{" "}
              {getLabel(data.length)} ważność!
            </Alert>
          )}
          {!data.length && (
            <Alert severity="success"  sx={{ mt: -2, mb: 2 }}>
              {greetings}, w ciągu kolejnych 30 dni brak terminów do przedłużenia!
            </Alert>
          )}
        </Grid>
      )}
      <Grid item xs={6}>
        <HistoryList />
      </Grid>
      <Grid item xs={6}>
        <EventsList />
      </Grid>
    </Grid>
  );
}
