import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Alert, Container } from "@mui/material";
import LoginImg from "../../static/login-img.jpg";
import { useSignUp } from "../../api/auth";
import React, { useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
  setIsLoggedIn: (token: string) => void;
}

export default function Login({ setIsLoggedIn }: Props) {
  const { mutate, data, isLoading, isSuccess, error } = useSignUp();
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("email") && data.get("password")) {
      mutate({
        email: data.get("email")!.toString(),
        password: data.get("password")!.toString(),
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setIsLoggedIn(data?.data.data);
    }
  }, [isSuccess, setIsLoggedIn, data?.data.data]);

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 18,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${LoginImg})`,
              backgroundRepeat: "no-repeat",
              filter: "blur(1px)",
              backgroundColor: (t) => t.palette.primary.dark,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Zaloguj się
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  variant={"standard"}
                  required
                  fullWidth
                  id="email"
                  label="Adres e-mail"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  variant={"standard"}
                  fullWidth
                  name="password"
                  label="Hasło"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type={"submit"}
                  sx={{ mt: 3, mb: 2 }}
                  fullWidth
                >
                  Zaloguj
                </LoadingButton>

                {error && <Alert severity="error">{error.message}</Alert>}
                {/*<Button*/}
                {/*    type="submit"*/}
                {/*    fullWidth*/}
                {/*    disabled={isLoading}*/}
                {/*    variant="contained"*/}
                {/*    sx={{mt: 3, mb: 2}}*/}
                {/*>*/}
                {/*    Zaloguj*/}
                {/*</Button>*/}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
