import * as yup from "yup";
import { FleetItem } from "../../../../api/fleet";
import dayjs from "dayjs";
// import dayjs, {Dayjs} from 'dayjs';

export const getInitialValues = (fleetItem?: FleetItem | null) => {
  return {
    registrationNumber: fleetItem ? fleetItem.registrationNumber : "",
    make: fleetItem ? fleetItem.make : "",
    model: fleetItem ? fleetItem.model : "",
    year: fleetItem ? fleetItem.year : "",
    mileage: fleetItem ? fleetItem.mileage : "",
    additionalInfo: fleetItem ? fleetItem.additionalInfo : "",
    vinNumber: fleetItem ? fleetItem.vinNumber : "",
    driverPhoneNumber:
      fleetItem && fleetItem.drivers[0]
        ? fleetItem.drivers[0].driver.phoneNumber.replace('+48', '')
        : "",
    driverName:
      fleetItem && fleetItem.drivers[0] ? fleetItem.drivers[0].driver.name : "",
    deadlines: fleetItem?.deadlines
      ? fleetItem.deadlines.map((deadline) => ({
          name: deadline.name,
          date: dayjs(deadline.date),
        }))
      : [
          {
            name: "Przegląd",
            date: null,
          },
          {
            name: "Ubezpieczenie",
            date: null,
          },
          {
            name: "Serwis",
            date: null,
          },
        ],
  };
};
export const validationSchema = yup.object({
  registrationNumber: yup
    .string()
    .min(5, "Numer rejestracyjny może się składać minimum z 5 znaków")
    .min(7, "Numer rejestracyjny może się składać maximum z 7 znaków")
    .required("Wymagany"),
  make: yup
    .string()
    .min(2, "Marka pojazdu może składać się z minimum 2 znaków")
    .max(15, "Marka pojazdu może składać się z maximum 15 znaków")
    .required("Wymagany"),
  model: yup
    .string()
    .min(2, "Model pojazdu może składać się z minimum 2 znaków")
    .max(15, "Model pojazdu może składać się z maximum 15 znaków")
    .required("Wymagany"),
  // year: yup
  //     .number()
  //     .optional()
  //     .typeError('Rok produkcji musi być liczbą')
  //     .test('len', 'Rok produkcji musi zawierać dokładnie 4 znaki', val => val!.toString().length === 4),
  // vinNumber: yup
  //     .string()
  //     .optional(),
  // additionalInfo: yup
  //     .string()
  //     .optional(),
  // mileage: yup
  //     .number()
  //     .optional()
  //     .typeError('Przebieg musi być liczbą'),
  // driverName: yup
  //     .string()
  //     .optional(),
  // driverPhoneNumber: yup
  //     .string()
  //     .optional(),
  deadlines: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .min(4, "Nazwa terminu za krótka")
          .required("Wymagane"), // these constraints take precedence
        date: yup.string().min(3, "Nieprawidłowa data").required("Wymagane"), // these constraints take precedence
      }),
    )
    .required(),
});
