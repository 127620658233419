import * as React from "react";
import { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  plPL,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {
  AttachmentData,
  useDeleteFile,
  useDownloadAttachment,
  useGetAttachments,
} from "../../../../api/attachment";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { useAppState } from "../../../../contexts/AppState";

export default function AttachmentsList() {
  const { data: attachmentsList, isLoading } = useGetAttachments();
  const [searchValue, setSearchValue] = useState<string>("");
  const [fileNameToDownload, setFileNameToDownload] =
    React.useState<string>("");
  const fileLink = React.useRef<HTMLAnchorElement>(null);

  const { setSnackbar } = useAppState();
  const {
    mutate: deleteFile,
    isSuccess: fileDeleted,
    // isLoading: fileDeleting,
    isError: isFileDeleteError,
    error: fileDeleteError,
  } = useDeleteFile();

  const columns: GridColDef[] = [
    { field: "name", headerName: "Nazwa pliku", flex: 1 },
    {
      field: "fleetItem",
      headerName: "Pojazd",
      flex: 1,
      renderCell: (val) => (
        <>
          {val.row.fleetItem.make} {val.row.fleetItem.model}
        </>
      ),
    },
    {
      field: "size",
      headerName: "Rozmiar",
      flex: 1,
      valueFormatter: ({ value }) => Math.round(Number(value) / 1024) + " KB",
    },
    { field: "createdAt", headerName: "Utworzono", type: "date", flex: 1 },
    {
      field: "createdBy",
      headerName: "Utworzono przez",
      flex: 1,
      renderCell: (val) => (
        <>
          {val.row.createdBy.firstName} {val.row.createdBy.lastName}
        </>
      ),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<DownloadIcon />}
          onClick={() => {
            onFileDownload(params.row.key);
          }}
          label="Pobierz"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => onDeleteClick(params.row.key)}
          label="Usuń"
        />,
      ],
    },
  ];

  const { data: fileData, isSuccess: fileDownloaded } =
    useDownloadAttachment(fileNameToDownload);

  useEffect(() => {
    if (fileLink.current && fileDownloaded) {
      fileLink.current.href = `data:application/octet-stream;base64,${fileData}`;
      fileLink.current.setAttribute("download", fileNameToDownload); //or any other extension
      fileLink.current.click();
      fileLink.current.href = "#";
      setFileNameToDownload("");
    }
  }, [fileDownloaded, fileData, fileNameToDownload]);
  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
  ) => {
    if (event) {
      setSearchValue(event.target.value);
    }
  };

  const onFileDownload = (fileName: string) => {
    setFileNameToDownload(fileName);
  };

  const onDeleteClick = (fileName: string) => {
    deleteFile(fileName);
  };

  useEffect(() => {
    if (fileDeleted) {
      setSnackbar({
        type: "success",
        message: "Plik został usunięty!",
      });
    }
  }, [fileDeleted, setSnackbar]);

  useEffect(() => {
    if (isFileDeleteError) {
      setSnackbar({
        type: "error",
        message: fileDeleteError?.message || "Wystąpił błąd...",
      });
    }
  }, [fileDeleteError, setSnackbar, isFileDeleteError]);

  const onFilter = ({ name, createdBy }: AttachmentData) =>
    (name + createdBy.firstName + createdBy.lastName)
      .toLowerCase()
      .indexOf(searchValue.toLowerCase()) !== -1;

  return (
    <div>
      <a ref={fileLink} style={{ display: "none" }} href={"/"}>
        Test
      </a>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={8}>
          <TextField
            value={searchValue}
            color={"primary"}
            onChange={handleChange}
            style={{ marginBottom: 20 }}
            fullWidth={true}
            label={"Szukaj"}
            placeholder={"nazwa pliku / opis / autor"}
            variant={"standard"}
          />
        </Grid>
        <Grid item xs={4}>
          <Tooltip title={"Funkcja niedostępna"}>
            <span>
              <Button variant={"contained"} fullWidth={true} disabled={true}>
                Dodaj plik
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <DataGrid
        autoHeight={true}
        disableRowSelectionOnClick
        disableColumnSelector
        loading={isLoading}
        rows={(attachmentsList || []).filter(onFilter)}
        columns={columns}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        style={{ cursor: "pointer" }}
        pageSizeOptions={[20, 50, 100]}
      />
    </div>
  );
}
