import * as React from "react";
import { useEffect } from "react";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Navigator from "./components/Navigator";
import Header from "./components/Header";
import { Route, Switch } from "wouter";
import Fleet from "./pages/Fleet";
import Dashboard from "./pages/Dashboard";
import EventsCalendar from "./pages/EventsCalendar";
import Attachments from "./pages/Attachments";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import {
  AppStateContextContextProvider,
  useAppState,
} from "./contexts/AppState";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import pl from "dayjs/locale/pl";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import FleetItemDetails from "./pages/Fleet/components/FleetItemDetails";
import Feedback from "./pages/Feedback";
import { ApiProvider } from "./contexts/ApiProvider";
import { theme, ThemeProviderWrapper } from "./contexts/ThemeProvider";
import AlertMessage from "./components/AlertMessage";
import { getUser } from "./utils/getUser";

dayjs.extend(relative);
dayjs.locale(pl);

const drawerWidth = 256;

axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("isLoggedIn");

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { setUser } = useAppState();
  const [isLoggedIn, setIsLoggedIn] = React.useState<string>(
    localStorage.getItem("isLoggedIn") || "",
  );
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
    if (isLoggedIn) {
      setUser(getUser(isLoggedIn));
      axios.defaults.headers.common["Authorization"] = `${isLoggedIn}`;
    }
  }, [isLoggedIn, setUser]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!isLoggedIn) {
    return (
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: theme.palette.grey[900],
        }}
      >
        <Switch>
          <Route path="/login">
            <Login setIsLoggedIn={setIsLoggedIn} />
          </Route>
          <Route>
            <Login setIsLoggedIn={setIsLoggedIn} />
          </Route>
        </Switch>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <AlertMessage />
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {isSmUp ? null : (
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            onLogOut={() => setIsLoggedIn("")}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        )}
        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          onLogOut={() => setIsLoggedIn("")}
          sx={{ display: { sm: "block", xs: "none" } }}
        />
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <Box component="main" sx={{ flex: 1, py: 6, px: 4 }}>
          <Switch>
            <Route path="/">
              <Dashboard />
            </Route>
            <Route path="/fleet">
              <Fleet />
            </Route>
            <Route path="/fleet/:id">
              {(params) => <FleetItemDetails itemId={params.id} />}
            </Route>
            <Route path="/calendar">
              <EventsCalendar />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/files">
              <Attachments />
            </Route>
            <Route path="/help">
              <Attachments />
            </Route>
            <Route path="/feedback">
              <Feedback />
            </Route>
            <Route path="/settings">
              <Settings />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Box>
      </Box>
    </Box>
  );
}

const AppWithProviders = () => (
  <ThemeProviderWrapper>
    <ApiProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
        <AppStateContextContextProvider>
          <App />
        </AppStateContextContextProvider>
      </LocalizationProvider>
    </ApiProvider>
  </ThemeProviderWrapper>
);

export default AppWithProviders;
