import * as React from "react";
import { useState } from "react";
import { DataGrid, GridColDef, plPL } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useGetUsers, User } from "../../../../api/users";
import Tooltip from "@mui/material/Tooltip";

const getColumns = (): GridColDef[] => [
  { field: "firstName", headerName: "Imię", flex: 1 },
  { field: "lastName", headerName: "Nazwisko", flex: 1 },
  { field: "email", headerName: "Adres e-mail", width: 300 },
  { field: "phoneNumber", headerName: "Numer telefonu", flex: 1 },
  { field: "isAdmin", headerName: "Administrator", type: "boolean", flex: 1 },
  { field: "created", headerName: "Utworzono", type: "date", flex: 1 },
];

export default function UsersList() {
  const { data: users, isLoading } = useGetUsers();
  const [searchValue, setSearchValue] = useState<string>("");
  const columns = getColumns();
  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
  ) => {
    if (event) {
      setSearchValue(event.target.value);
    }
  };
  const onFilter = ({ firstName, lastName, email }: User) =>
    (firstName + lastName + email)
      .toLowerCase()
      .indexOf(searchValue.toLowerCase()) !== -1;

  return (
    <div>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={8}>
          <TextField
            value={searchValue}
            color={"primary"}
            onChange={handleChange}
            style={{ marginBottom: 20 }}
            fullWidth={true}
            label={"Szukaj"}
            placeholder={"imię / nazwisko / adres e-mail"}
            variant={"standard"}
          />
        </Grid>
        <Grid item xs={4}>
          <Tooltip title={"Funkcja niedostępna"}>
            <span>
              <Button variant={"contained"} fullWidth={true} disabled={true}>
                Dodaj użytkownika
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <DataGrid
        autoHeight={true}
        disableRowSelectionOnClick
        disableColumnSelector
        loading={isLoading}
        rows={(users || []).filter(onFilter)}
        columns={columns}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        style={{ cursor: "pointer" }}
        pageSizeOptions={[20, 50, 100]}
      />
    </div>
  );
}
