import React, { useState } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";

type FeedbackFormState = {
  title: string;
  description: string;
};

const FeedbackForm: React.FC = () => {
  const [formState, setFormState] = useState<FeedbackFormState>({
    title: "",
    description: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Wyślij dane formularza do serwera lub wykonaj inne operacje
    console.log(formState);
    // Resetuj stan formularza
    setFormState({ title: "", description: "" });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" align="center" gutterBottom>
        Formularz zgłaszania błędu
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Tytuł"
          name="title"
          value={formState.title}
          onChange={handleInputChange}
          margin="normal"
          variant="standard"
        />
        <TextField
          fullWidth
          label="Opis"
          name="description"
          value={formState.description}
          onChange={handleInputChange}
          margin="normal"
          variant="standard"
          multiline
          rows={4}
        />
        <Button type="submit" variant="contained" color="primary">
          Wyślij
        </Button>
      </form>
    </Container>
  );
};

export default FeedbackForm;
