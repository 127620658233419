import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DateCalendar } from "@mui/x-date-pickers";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (date: string | null) => void;
}

const DeadlineDateDialog = ({ isVisible, onClose, onSave }: Props) => {
  const [date, setDate] = React.useState<string | null>(null);

  return (
    <Dialog open={isVisible} onClose={onClose} maxWidth={"md"}>
      <DialogTitle>Wybierz datę</DialogTitle>
      <DialogContent>
        <DateCalendar value={date} onChange={(newValue) => setDate(newValue)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={"secondary"}>
          Anuluj
        </Button>
        <Button
          onClick={() => {
            onSave(date);
            onClose();
          }}
        >
          Wybierz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeadlineDateDialog;
