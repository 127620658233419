import { Alert } from "@mui/material";
import * as React from "react";
import { useAppState } from "../../contexts/AppState";
import Snackbar from "@mui/material/Snackbar";

const AlertStyles = {
  position: "fixed",
  zIndex: "9999",
  bottom: "20px",
  left: "50%",
  transform: "translateX(-50%)",
};

const AlertMessage = () => {
  const { snackbar, setSnackbar } = useAppState();
  const onClose = () => {
    setSnackbar(null);
  };
  if (!snackbar) {
    return null;
  }
  if (snackbar.type === "success") {
    return (
      <Snackbar
        open={snackbar.type === "success"}
        autoHideDuration={6000}
        onClose={onClose}
      >
        <Alert onClose={onClose} severity="success" sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    );
  }
  if (snackbar.type === "error") {
    return (
      <Alert severity="error" onClose={onClose} sx={AlertStyles}>
        {snackbar.message}
      </Alert>
    );
  }

  if (snackbar.type === "warning") {
    return (
      <Alert severity="warning" sx={AlertStyles}>
        {snackbar.message}
      </Alert>
    );
  }
  return null;
};

export default AlertMessage;
