import { Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useEffect, useState } from "react";
import { useEditFleet } from "../../../../api/fleet";
import { useAppState } from "../../../../contexts/AppState";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import AttachFile from "@mui/icons-material/Save";

interface Props {
  initialValue?: string;
  itemId: string;
}

export default function AdditionalInfo({ itemId, initialValue }: Props) {
  const [value, setValue] = useState<string>(initialValue || "");

  const { setSnackbar } = useAppState();
  const {
    mutate: updateItem,
    isError: isUpdateError,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
  } = useEditFleet();

  useEffect(() => {
    if (isUpdateSuccess) {
      setSnackbar({
        type: "success",
        message: "Pojazd został zaktualizowany!",
      });
    }
  }, [isUpdateSuccess, setSnackbar]);

  useEffect(() => {
    if (isUpdateError) {
      setSnackbar({
        type: "error",
        message: "Coś poszło nie tak...",
      });
    }
  }, [isUpdateError, setSnackbar]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const onSave = () => {
    updateItem({
      id: Number(itemId),
      data: {
        additionalInfo: value,
      },
    });
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent sx={{ position: "relative" }}>
        <Grid container alignItems={"center"} sx={{ mb: 2 }}>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
              Dodatkowe informacje
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <LoadingButton
              loading={isUpdating}
              size={"small"}
              color={"primary"}
              fullWidth={false}
              startIcon={<AttachFile />}
              onClick={onSave}
            >
              Zapisz
            </LoadingButton>
          </Grid>
        </Grid>
        <TextField
          id="outlined-multiline-static"
          multiline
          fullWidth={true}
          // onBlur={onBlur}
          disabled={isUpdating}
          onChange={onChange}
          value={value}
          minRows={4}
        />
      </CardContent>
    </Card>
  );
}
