import axios, { AxiosError } from "axios";
import { EXTEND_DEADLINE_URL } from "../constants/urls";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

interface ExtendDeadlineRequest {
  deadlineID: number;
  date: Date;
}

interface ExtendDeadlineResponse {
  data: {
    data: {
      id: number;
      name: string;
      date: Date;
      fleetItemId: number;
    };
  };
}

interface DeadlinesResponse {
  data: {
    id: number;
    name: string;
    date: Date;
    fleetItemId: number;
    fleetItem: {
      make: string;
      model: string;
      registrationNumber: string;
    };
  }[];
}

const extendDeadline = ({ deadlineID, date }: ExtendDeadlineRequest) =>
  axios.patch(`${EXTEND_DEADLINE_URL}/${deadlineID}`, { date }).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

const getDeadlines = (daysOffset?: number) => {
    const queryParams = daysOffset ? `?daysOffset=${daysOffset}` : '';
    return axios.get<DeadlinesResponse>(`${EXTEND_DEADLINE_URL}${queryParams}`).catch((e) => {
        if (e.response && e.response.data.message) {
            e.message = e.response.data.message;
        }
        throw e;
    });
}

export function useExtendDeadline() {
  const client = useQueryClient();
  return useMutation<ExtendDeadlineResponse, AxiosError, ExtendDeadlineRequest>(
    {
      mutationFn: extendDeadline,
      onSuccess: ({ data }) => {
        const queryData = client.invalidateQueries();
        console.log(queryData);
      },
    },
  );
}

export const useGetDeadlines = (daysOffset?: number) => {
  return useQuery(["getDeadlines", daysOffset], () => getDeadlines(daysOffset), {
    select: ({ data }) => data.data,
  });
};
