import dayjs from "dayjs";
import { DATE_FORMAT } from "../constants/date";

export const parseHistoryLogDescription = (
  description: string,
  createdBy: {
    firstName: string;
    lastName: string;
  },
  fleetItemData?: string,
) => {
  if (description.includes("FLEET_ITEM_ADDED")) {
    return `Pojazd został dodany przez ${createdBy.firstName} ${createdBy.lastName}`;
  }
  if (description.includes("FLEET_ITEM_UPDATED")) {
    return `Pojazd został zaktualizowany przez ${createdBy.firstName} ${createdBy.lastName}`;
  }
  if (description.includes("EXTENDED")) {
    const deadlineName = description.split("_")[2];
    const deadlineDate = description.split("_")[3];
    return `Termin ${deadlineName} został przedłużony do ${dayjs(
      deadlineDate,
    ).format(DATE_FORMAT)} przez ${createdBy.firstName} ${createdBy.lastName}`;
  }
  if (description.includes("ATTACHMENT_UPLOADED")) {
    const fileName = description.replace("ATTACHMENT_UPLOADED_", "");
    return `Plik ${fileName} został dodany przez ${createdBy.firstName} ${createdBy.lastName}`;
  }

  if (description.includes("FLEET_SERVICE_LOG_ITEM_CREATED_")) {
    const serviceType = description.replace("FLEET_SERVICE_LOG_ITEM_CREATED_", "");
    return `Serwis ${serviceType} został dodany przez ${createdBy.firstName} ${createdBy.lastName}`;
  }

  if (description.includes("FLEET_SERVICE_LOG_ITEM_UPDATED_")) {
    const serviceType = description.replace("FLEET_SERVICE_LOG_ITEM_UPDATED_", "");
    return `Serwis ${serviceType} został zaktualizowany przez ${createdBy.firstName} ${createdBy.lastName}`;
  }

  if (description.includes("FLEET_SERVICE_LOG_ITEM_DELETED_")) {
    const serviceType = description.replace("FLEET_SERVICE_LOG_ITEM_DELETED_", "");
    return `Serwis ${serviceType} został usunięty przez ${createdBy.firstName} ${createdBy.lastName}`;
  }

  return description;
};
