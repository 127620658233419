import * as React from "react";
import { useEffect } from "react";
import { Avatar, Card, CardContent, List, ListItemAvatar } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AttachFile from "@mui/icons-material/AttachFile";
import { Skeleton } from "@mui/lab";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FileIcon from "@mui/icons-material/FileOpen";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../constants/date";
import { Attachment } from "../../../../api/fleet";
import {
  useDeleteFile,
  useDownloadAttachment,
  useUploadAttachment,
} from "../../../../api/attachment";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppState } from "../../../../contexts/AppState";

interface Props {
  fleetItemId: string;
  attachments: Attachment[];
  showLoader: boolean;
}

const listItem = { mx: 0, px: 0 };
const AttachmentsList = ({ fleetItemId, attachments, showLoader }: Props) => {
  const inputFile = React.useRef<HTMLInputElement>(null);
  const [fileNameToDownload, setFileNameToDownload] =
    React.useState<string>("");
  const fileLink = React.useRef<HTMLAnchorElement>(null);
  const { setSnackbar } = useAppState();
  const {
    mutate: deleteFile,
    isSuccess: fileDeleted,
    isLoading: fileDeleting,
    isError: fileDeleteError,
  } = useDeleteFile();
  const { mutate, isError, isLoading, isSuccess, error } =
    useUploadAttachment();
  const { data: fileData, isSuccess: fileDownloaded } =
    useDownloadAttachment(fileNameToDownload);

  useEffect(() => {
    if (fileLink.current && fileDownloaded) {
      fileLink.current.href = `data:application/octet-stream;base64,${fileData}`;
      fileLink.current.setAttribute("download", fileNameToDownload); //or any other extension
      fileLink.current.click();
      fileLink.current.href = "#";
      setFileNameToDownload("");
    }
  }, [fileDownloaded, fileData, fileNameToDownload]);

  useEffect(() => {
    if (isSuccess) {
      setSnackbar({
        type: "success",
        message: "Plik został dodany!",
      });
    }
  }, [isSuccess, setSnackbar]);

  useEffect(() => {
    if (isError) {
      setSnackbar({
        type: "error",
        message: error?.message || "Wystąpił błąd...",
      });
    }
  }, [isError, error, setSnackbar]);

  useEffect(() => {
    if (fileDeleted) {
      setSnackbar({
        type: "success",
        message: "Plik został usunięty!",
      });
    }
  }, [fileDeleted, setSnackbar]);

  useEffect(() => {
    if (fileDeleteError) {
      setSnackbar({
        type: "error",
        message: error?.message || "Wystąpił błąd...",
      });
    }
  }, [fileDeleteError, setSnackbar, error]);

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target?.files || [])[0];

    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fleetItemId", String(fleetItemId));
      mutate(formData);
    }
  };

  const onDeleteClick = (fileName: string) => {
    deleteFile(fileName);
  };

  const onAddFileButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current!.click();
  };

  const onFileDownload = (fileName: string) => {
    setFileNameToDownload(fileName);
  };

  return (
    <Card sx={{ mt: 2 }}>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={onFileSelect}
      />
      <a ref={fileLink} style={{ display: "none" }} href={"/"}>
        Test
      </a>
      <CardContent>
        <Grid container alignItems={"center"}>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
              Załączone dokumenty
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{textAlign: 'right'}}>
            <LoadingButton
              loading={isLoading}
              size={"small"}
              color={"primary"}
              startIcon={<AttachFile />}
              onClick={onAddFileButtonClick}
            >
              Dodaj plik
            </LoadingButton>
          </Grid>
        </Grid>
        <List>
          {showLoader &&
            Array.from(Array(7).keys()).map((i) => (
              <Skeleton key={i} height={60} />
            ))}
          {!showLoader &&
            attachments.map((attachment) => (
              <ListItem
                sx={listItem}
                secondaryAction={
                  <>
                    <IconButton
                      edge="end"
                      aria-label="Download"
                      onClick={() => onFileDownload(attachment.key)}
                    >
                      <DownloadIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={fileDeleting}
                      onClick={() => onDeleteClick(attachment.key)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <FileIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={attachment.name}
                  secondary={`${dayjs(attachment.createdAt).format(
                    DATE_FORMAT,
                  )} przez ${attachment.createdBy.firstName} ${
                    attachment.createdBy.lastName
                  }`}
                />
              </ListItem>
            ))}
        </List>

        {!attachments.length && (
          <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
            Brak załączonych plików
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default AttachmentsList;
