import * as React from "react";
import { useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  plPL,
} from "@mui/x-data-grid";
import { Deadline, FleetItem, useFleet } from "../../../../api/fleet";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddFleetItem from "../AddFleetItem";
import dayjs from "dayjs";
import { useLocation } from "wouter";

const getColor = (date: Date) => {
  if (dayjs(date).diff(new Date(), "day") < 8) {
    return "red";
  }
  if (dayjs(date).diff(new Date(), "day") < 31) {
    return "yellow";
  }
  return "white";
};

const getLatestDeadline = (deadlines: Deadline[], expandAll: boolean) => {
  const sortedDeadlines = deadlines.sort((a, b) =>
    dayjs(a.date).diff(b.date, "day", true),
  );
  return (
    <ul style={{ listStyle: "none", padding: 0 }}>
      {sortedDeadlines.map((deadline, index) =>
        index !== 0 && !expandAll ? null : (
          <li style={{ color: getColor(deadline.date) }}>
            {deadline.name} - {dayjs(deadline.date).fromNow()} -{" "}
            {dayjs(deadline.date).format("DD.MM.YYYY")}
          </li>
        ),
      )}
    </ul>
  );
};
const getColumns = (expandAll: boolean): GridColDef[] => [
  { field: "registrationNumber", headerName: "Rejestracja", width: 100 },
  { field: "make", headerName: "Marka", width: 130 },
  { field: "model", headerName: "Model", width: 130 },
  {
    field: "year",
    headerName: "Rok produkcji",
    width: 100,
  },
  {
    field: "mileage",
    headerName: "Przebieg",
    width: 120,
  },
  {
    field: "deadlines",
    headerName: "Najbliższy termin",
    minWidth: 300,
    sortComparator: (v1, v2) => {
      const [latest1] = v1.sort((a: any, b: any) =>
        dayjs(a.date).diff(b.date, "day", true),
      );
      const [latest2] = v2.sort((a: any, b: any) =>
        dayjs(a.date).diff(b.date, "day", true),
      );
      return dayjs(latest1.date).diff(latest2.date, "day", true);
    },
    renderCell: (params: GridRenderCellParams<FleetItem>) => {
      return <>{getLatestDeadline(params.row.deadlines, expandAll)}</>;
    },
  },
  {
    field: "additionalInfo",
    headerName: "Dodatkowe informacje",
    hideSortIcons: true,
    minWidth: 200,
  },
];

export default function FleetList() {
  const { data: fleetItems, isLoading } = useFleet();
  const [, navigate] = useLocation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [addItemOpen, setAddItemOpened] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<FleetItem | null>(null);
  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
  ) => {
    if (event) {
      setSearchValue(event.target.value);
    }
  };
  const handleAddItem = () => {
    setAddItemOpened(true);
  };

  const handleDetails = (itemId: number) => {
    navigate(`/fleet/${itemId}`);
  };

  const columns = getColumns(!!searchValue);

  const handleAddFleetItemClose = () => {
    setItemToEdit(null);
    setAddItemOpened(false);
  };
  const onFilter = ({ registrationNumber, model, make }: FleetItem) =>
    (registrationNumber + model + make)
      .toLowerCase()
      .indexOf(searchValue.toLowerCase()) !== -1;
  return (
    <div>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={8}>
          <TextField
            value={searchValue}
            color={"primary"}
            onChange={handleChange}
            style={{ marginBottom: 20 }}
            fullWidth={true}
            label={"Szukaj"}
            placeholder={"numer rejestracji / marka / model"}
            variant={"standard"}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant={"contained"}
            fullWidth={true}
            onClick={handleAddItem}
          >
            Dodaj pojazd
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        autoHeight={true}
        getRowHeight={() => 'auto'}
        disableRowSelectionOnClick
        loading={isLoading}
        disableColumnSelector
        rows={(fleetItems || []).filter(onFilter)}
        columns={columns}
        // getRowHeight={() => 'auto'}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        style={{ cursor: "pointer" }}
        onRowClick={(params) => handleDetails(params.row.id)}
        pageSizeOptions={[20, 50, 100]}
      />
      <AddFleetItem
        open={addItemOpen || !!itemToEdit}
        onClose={handleAddFleetItemClose}
        itemToEdit={itemToEdit}
      />
    </div>
  );
}
