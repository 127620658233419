import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {useLocation} from "wouter";

export default function NotFound() {
    const [, setLocation] = useLocation();
  return (
    <Grid container spacing={2}>
      <Grid xs={12} sx={{ textAlign: "center" }}>
        <Typography variant="h1">404</Typography>
        <Typography variant="h6">
          Ups, nie ma takiej strony...
        </Typography>
        <Button sx={{mt: 2}} variant="contained" onClick={() => setLocation('/')}>Powrót do strony głównej</Button>
      </Grid>
    </Grid>
  );
}
