import * as React from "react";
import { getIn, useFormikContext } from "formik";
import TextField from "@mui/material/TextField";

interface Props {
  fieldName: string;
  label: string;
  multiline?: boolean;
  disabled?: boolean;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
}

export const FormTextField = ({
  fieldName,
  label,
  multiline,
  disabled,
  suffix,
  prefix,
}: Props) => {
  const formikContext = useFormikContext();
  const touched = getIn(formikContext.touched, fieldName);
  const error = touched ? getIn(formikContext.errors, fieldName) : 0;
  return (
    <TextField
      margin="dense"
      id={fieldName}
      disabled={disabled}
      label={label}
      type="text"
      onChange={formikContext.handleChange}
      value={getIn(formikContext.values, fieldName) || ''}
      fullWidth
      variant="standard"
      helperText={touched && error}
      error={Boolean(error)}
      InputProps={{
        endAdornment: suffix,
        startAdornment: prefix,
      }}
      multiline={multiline || false}
    />
  );
};
