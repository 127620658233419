import jwt_decode from "jwt-decode";

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  orgName: string;
}

export const getUser = (token: string): UserData => jwt_decode(token) as UserData;
