import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormikProvider, getIn, useFormik } from "formik";
import { validationSchema } from "./utils";
import Grid from "@mui/material/Grid";
import { FormTextField } from "./FormTextField";
import {
  ServiceLogItem,
  useAddServiceLog,
  useEditServiceLog,
} from "../../../../api/fleet";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppState } from "../../../../contexts/AppState";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

interface Props {
  open: boolean;
  onClose: () => void;
  fleetItemId: number;
  serviceLog: ServiceLogItem | null;
}

export default function AddServiceLog({
  open,
  onClose,
  fleetItemId,
  serviceLog,
}: Props) {
  const initialValues = {
    type: serviceLog?.type,
    serviceDate: serviceLog ? dayjs(serviceLog?.serviceDate) : undefined,
    mileage: serviceLog?.mileage,
    description: serviceLog?.description,
  };
  const { setSnackbar } = useAppState();
  const {
    mutate: addItem,
    isError,
    isLoading,
    error,
    isSuccess,
  } = useAddServiceLog();
  const {
    mutate: updateItem,
    isError: isUpdateError,
    isLoading: isUpdating,
    error: updateError,
    isSuccess: isUpdateSuccess,
  } = useEditServiceLog();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: ({ type, serviceDate, description, mileage, ...rest }) => {
      const newServiceLog = {
        ...rest,
        fleetItemId,
        mileage: Number(mileage!),
        description: description,
        type: type!,
        serviceDate: serviceDate!.toDate(),
      };
      if (serviceLog) {
        updateItem({ id: serviceLog.id, data: newServiceLog });
      } else {
        addItem(newServiceLog);
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setSnackbar({
        type: "success",
        message: "Serwis został dodany!",
      });
      onClose();
    }
  }, [isSuccess, setSnackbar, onClose]);

  useEffect(() => {
    if (isError) {
      setSnackbar({
        type: "error",
        message: error?.message || "Wystąpił błąd...",
      });
    }
  }, [isError, error, setSnackbar]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setSnackbar({
        type: "success",
        message: "Pojazd został zaktualizowany!",
      });
      onClose();
    }
  }, [isUpdateSuccess, setSnackbar, onClose]);

  useEffect(() => {
    if (isUpdateError) {
      setSnackbar({
        type: "error",
        message: updateError?.message || "Wystąpił błąd...",
      });
    }
  }, [isUpdateError, updateError, setSnackbar]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
      <DialogTitle>{serviceLog ? "Edytuj wpis" : "Dodaj serwis"}</DialogTitle>
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          noValidate={true}
          autoComplete="off"
        >
          <DialogContent sx={{ overflowX: "hidden" }}>
            <Grid container columnSpacing={2} sx={{ mb: 3 }}>
              <Grid item xs={4}>
                <DatePicker
                  slotProps={{
                    textField: {
                      // error: getIn(formik.touched, `deadlines[${index}].date`) && getIn(formik.touched, `deadlines[${index}].date`),
                      margin: "dense",
                      fullWidth: true,
                      variant: "standard",
                      name: `serviceDate`,
                      error:
                        !!getIn(formik.touched, `serviceDate`) &&
                        !!getIn(formik.errors, `serviceDate`),
                      helperText:
                        !!getIn(formik.touched, `serviceDate`) &&
                        getIn(formik.errors, `serviceDate`),
                    },
                  }}
                  onChange={(value) => {
                    formik.setFieldValue(`serviceDate`, dayjs(value));
                  }}
                  value={getIn(formik.values, "serviceDate") || ""}
                  label="Data serwisu"
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField fieldName={"type"} label={"Typ serwisu"} />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  fieldName={"mileage"}
                  label={"Przebieg"}
                  suffix={"KM"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  fieldName={"description"}
                  label={"Dodatkowe informacje"}
                  multiline={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Anuluj</Button>
            <LoadingButton loading={isLoading || isUpdating} type={"submit"}>
              {serviceLog ? "Zapisz serwis" : "Dodaj serwis"}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
}
