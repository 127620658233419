import * as React from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useHistoryLog } from "../../../../api/history";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import { useLocation } from "wouter";
import Divider from "@mui/material/Divider";
import { parseHistoryLogDescription } from "../../../../utils/parseHistoryEvent";
import { Skeleton } from "@mui/lab";

export default function HistoryList() {
  const { data: history, isLoading, isError, isSuccess } = useHistoryLog(10);
  const [, setLocation] = useLocation();
  const goToFleetItem = (fleetId: number) => {
    setLocation(`/fleet/${fleetId}`);
  };
  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
          Historia zdarzeń
        </Typography>
        {isError && <span>Ups... Coś poszło nie tak! :(</span>}
        {isLoading &&
          Array.from(Array(10).keys()).map((i) => (
            <Skeleton key={i} height={40} />
          ))}
        {!isLoading && (
          <List>
            {isSuccess && !history.length && (
              <span>Nic to jeszcze nie ma...</span>
            )}
            {(history || []).map((item, index) => (
              <>
                <ListItem
                  disablePadding
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      onClick={() => goToFleetItem(item.fleetItem.id)}
                    >
                      <TimeToLeaveIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    sx={{ pr: "20px" }}
                    primary={`${dayjs(item.createdAt).format(
                      "DD.MM.YYYY - HH:MM",
                    )} ${item.fleetItem.make} ${item.fleetItem.model} (${
                      item.fleetItem.registrationNumber
                    })`}
                    secondary={parseHistoryLogDescription(
                      item.description,
                      item.createdBy,
                    )}
                  />
                </ListItem>
                {index < (history || []).length - 1 && (
                  <Divider component="li" />
                )}
              </>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
}
