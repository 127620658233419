import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

interface ApiProviderProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient();
export const ApiProvider = ({ children }: ApiProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
