import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import DeadlineDateDialog from "../DeadlineDateDialog";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../constants/date";
import { useExtendDeadline } from "../../../../api/deadline";
import { CUSTOM_DATE_INDEX, getDate, options } from "./utils";
import { Deadline } from "../../../../api/fleet";
import { useAppState } from "../../../../contexts/AppState";
import { useEffect } from "react";

interface Props {
  deadline: Deadline;
}

export default function DeadlineButton({
  deadline: { id, name, date },
}: Props) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [datePickerOpened, setDatePickerOpened] =
    React.useState<boolean>(false);
  const [customDateValue, setCustomDateValue] = React.useState<string | null>(
    null,
  );
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(1);
  const { setSnackbar } = useAppState();
  const { mutate, isSuccess, isError, isLoading } = useExtendDeadline();

  useEffect(() => {
    if (isSuccess) {
      setSnackbar({
        type: "success",
        message: `Termin ${name} został zaktualziowany`,
      });
    } else if (isError) {
      setSnackbar({
        type: "error",
        message: `Wystąpił błąd podczas aktualizacji terminu ${name}`,
      });
    }
  }, [isSuccess, isError, setSnackbar, name]);
  const handleClick = () => {
    if (selectedIndex !== CUSTOM_DATE_INDEX || customDateValue) {
      const extendDate = customDateValue
        ? dayjs(customDateValue).toDate()
        : getDate(selectedIndex, date);
      mutate({ deadlineID: id!, date: extendDate });
    }
    // custom date not selected
    if (selectedIndex === CUSTOM_DATE_INDEX && !customDateValue) {
      setDatePickerOpened(true);
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    if (index === 3) {
      setDatePickerOpened(true);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //     return;
    // }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <DeadlineDateDialog
        isVisible={datePickerOpened}
        onClose={() => setDatePickerOpened(false)}
        onSave={(newValue) => setCustomDateValue(newValue)}
      />

      <ButtonGroup
        variant="contained"
        fullWidth
        ref={anchorRef}
        aria-label="split button"
        disabled={isLoading}
      >
        <Button onClick={handleClick} size={"small"}>
          {selectedIndex === 3 && customDateValue
            ? `Przedłuż do ${dayjs(customDateValue).format(DATE_FORMAT)}`
            : options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          sx={{ width: 20 }}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          width: anchorRef.current && anchorRef.current["clientWidth"],
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
