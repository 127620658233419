import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useLocation } from "wouter";
import { routes } from "../Navigator/Navigator";
import { useTheme } from "@mui/material";
import { useAppState } from "../../contexts/AppState";

interface HeaderProps {
  onDrawerToggle: () => void;
}

const getHeader = (location: string): string => {
  if (/\/fleet\/\d+/.test(location)) {
    return "Szczegóły pojazdu";
  } else {
    const labels = routes.flatMap(({ children }) =>
      children.map((item) => item),
    );
    return (
      labels.find(({ link }) => link === location)?.id ||
      ("Not found" as string)
    );
  }
};

export default function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;
  const theme = useTheme();
  const [location] = useLocation();
  const { user } = useAppState();
  const initials = user ? user.firstName[0] + user.lastName[0] : "KP";
  return (
    <React.Fragment>
      <AppBar
        color="transparent"
        position="sticky"
        style={{ height: "56px", paddingTop: "4px", backgroundColor: "#fff" }}
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography
                color={theme.palette.background.default}
                variant="h5"
                component="h1"
              >
                {getHeader(location)}
              </Typography>
            </Grid>
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            {/*<Grid item>*/}
            {/*    <Tooltip title="Alerts • No alerts">*/}
            {/*        <IconButton color="primary">*/}
            {/*            <NotificationsIcon/>*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*</Grid>*/}
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
                <Avatar
                  sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
                >
                  {initials}
                </Avatar>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
