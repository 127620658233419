import axios from "axios";
import { GET_USERS_URL } from "../constants/urls";
import { useQuery } from "@tanstack/react-query";

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  created: Date;
  phoneNumber: string;
  isAdmin: boolean;
}

export interface Users {
  data: User[];
}

const getUsers = () =>
  axios.get<Users>(`${GET_USERS_URL}`).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

export const useGetUsers = () => {
  return useQuery(["getUsers"], getUsers, {
    select: ({ data }) =>
      data.data.map((i) => ({ ...i, created: new Date(i.created) })),
  });
};

export const usersMock: Users = {
  data: [
    {
      id: 1,
      firstName: "Jan",
      lastName: "Kowalski",
      email: "jan.kowalski@example.com",
      created: new Date("2022-01-01"),
      phoneNumber: "123456789",
      isAdmin: false,
    },
    {
      id: 2,
      firstName: "Anna",
      lastName: "Nowak",
      email: "anna.nowak@example.com",
      created: new Date("2022-02-01"),
      phoneNumber: "987654321",
      isAdmin: true,
    },
    {
      id: 3,
      firstName: "Adam",
      lastName: "Wiśniewski",
      email: "adam.wisniewski@example.com",
      created: new Date("2022-03-01"),
      phoneNumber: "555666777",
      isAdmin: false,
    },
    {
      id: 4,
      firstName: "Katarzyna",
      lastName: "Dąbrowska",
      email: "katarzyna.dabrowska@example.com",
      created: new Date("2022-04-01"),
      phoneNumber: "111222333",
      isAdmin: false,
    },
    {
      id: 5,
      firstName: "Piotr",
      lastName: "Lewandowski",
      email: "piotr.lewandowski@example.com",
      created: new Date("2022-05-01"),
      phoneNumber: "444555666",
      isAdmin: true,
    },
    {
      id: 6,
      firstName: "Magdalena",
      lastName: "Kamińska",
      email: "magdalena.kaminska@example.com",
      created: new Date("2022-06-01"),
      phoneNumber: "777888999",
      isAdmin: false,
    },
    {
      id: 7,
      firstName: "Tomasz",
      lastName: "Zieliński",
      email: "tomasz.zielinski@example.com",
      created: new Date("2022-07-01"),
      phoneNumber: "999888777",
      isAdmin: false,
    },
    {
      id: 8,
      firstName: "Małgorzata",
      lastName: "Szymańska",
      email: "malgorzata.szymanska@example.com",
      created: new Date("2022-08-01"),
      phoneNumber: "123123123",
      isAdmin: true,
    },
    {
      id: 9,
      firstName: "Marcin",
      lastName: "Woźniak",
      email: "marcin.wozniak@example.com",
      created: new Date("2022-09-01"),
      phoneNumber: "456456456",
      isAdmin: false,
    },
    {
      id: 10,
      firstName: "Agnieszka",
      lastName: "Kowalczyk",
      email: "agnieszka.kowalczyk@example.com",
      created: new Date("2022-10-01"),
      phoneNumber: "789789789",
      isAdmin: true,
    },
  ],
};
