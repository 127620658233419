import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FieldArray, FormikProvider, getIn, useFormik } from "formik";
import { getInitialValues, validationSchema } from "./utils";
import Grid from "@mui/material/Grid";
import { FormTextField } from "./FormTextField";
import { IconButton, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/RemoveCircleOutline";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { FleetItem, useAddFleet, useEditFleet } from "../../../../api/fleet";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppState } from "../../../../contexts/AppState";

interface Props {
  open: boolean;
  onClose: () => void;
  itemToEditId?: number;
  itemToEdit: FleetItem | null;
}

export default function AddFleetItem({
  open,
  onClose,
  itemToEdit,
  itemToEditId,
}: Props) {
  const initialValues = getInitialValues(itemToEdit);
  const { setSnackbar } = useAppState();
  const {
    mutate: addItem,
    isError,
    isLoading,
    error,
    isSuccess,
  } = useAddFleet();
  const {
    mutate: updateItem,
    isError: isUpdateError,
    isLoading: isUpdating,
    error: updateError,
    isSuccess: isUpdateSuccess,
  } = useEditFleet();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: ({
      driverName,
      year,
      deadlines,
      mileage,
      driverPhoneNumber,
      ...rest
    }) => {
      const fleetItem = {
        ...rest,
        year: year ? Number(year) : undefined,
        mileage: mileage ? Number(mileage) : undefined,
        deadlines: deadlines.map((deadline) => ({
          ...deadline,
          date: deadline.date!.toDate(),
        })),
        drivers:
          driverName && driverPhoneNumber
            ? [
                {
                  name: driverName,
                  phoneNumber: "+48" + driverPhoneNumber,
                },
              ]
            : [],
      };
      if (itemToEdit && itemToEditId) {
        updateItem({ id: itemToEditId, data: fleetItem });
      } else {
        addItem(fleetItem);
      }
      // alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setSnackbar({
        type: "success",
        message: "Pojazd został dodany!",
      });
      onClose();
    }
  }, [isSuccess, setSnackbar, onClose]);

  useEffect(() => {
    if (isError) {
      setSnackbar({
        type: "error",
        message: error?.message || "Wystąpił błąd...",
      });
    }
  }, [isError, error, setSnackbar]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setSnackbar({
        type: "success",
        message: "Pojazd został zaktualizowany!",
      });
      onClose();
    }
  }, [isUpdateSuccess, setSnackbar, onClose]);

  useEffect(() => {
    if (isUpdateError) {
      setSnackbar({
        type: "error",
        message: updateError?.message || "Wystąpił błąd...",
      });
    }
  }, [isUpdateError, updateError, setSnackbar]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
      <DialogTitle>{itemToEditId ? 'Edytuj pojazd' : 'Dodaj pojazd'}</DialogTitle>
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          noValidate={true}
          autoComplete="off"
        >
          <DialogContent sx={{ overflowX: "hidden" }}>
            <Grid container columnSpacing={2} sx={{ mb: 3 }}>
              <Grid item xs={6}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <FormTextField
                      fieldName={"registrationNumber"}
                      label={"Numer rejestracyjny"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField
                      fieldName={"vinNumber"}
                      label={"Numer VIN"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <FormTextField fieldName={"make"} label={"Marka pojazdu"} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField
                      fieldName={"model"}
                      label={"Model pojazdu"}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <FormTextField fieldName={"year"} label={"Rok produkcji"} />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField fieldName={"mileage"} label={"Przebieg"} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  fieldName={"additionalInfo"}
                  label={"Dodatkowe informacje"}
                  multiline={true}
                />
              </Grid>

              <Grid item xs={6}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <FormTextField
                      fieldName={"driverName"}
                      label={"Imię i nazwisko kierowcy"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField
                      prefix={"+48"}
                      fieldName={"driverPhoneNumber"}
                      label={"Numer telefonu kierowcy"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Typography component={"h3"}>Terminy</Typography>
            <Grid container columnSpacing={4}>
              <FieldArray
                name={"deadlines"}
                render={(arrayHelpers) => (
                  <React.Fragment>
                    {formik.values.deadlines.map((deadline, index) => (
                      <Grid item xs={6} key={index}>
                        <Paper
                          sx={{ mt: 2, p: 2, mx: -1, position: "relative" }}
                          square={true}
                          key={index}
                        >
                          <Grid container columnSpacing={2}>
                            <Grid item xs={6}>
                              <IconButton
                                aria-label="delete"
                                size={"small"}
                                onClick={() => arrayHelpers.remove(index)}
                                sx={{
                                  position: "absolute",
                                  right: -20,
                                  top: -20,
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <FormTextField
                                fieldName={`deadlines[${index}].name`}
                                label={" "}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DatePicker
                                slotProps={{
                                  textField: {
                                    // error: getIn(formik.touched, `deadlines[${index}].date`) && getIn(formik.touched, `deadlines[${index}].date`),
                                    margin: "dense",
                                    variant: "standard",
                                    name: `deadlines[${index}].date`,
                                    error:
                                      !!getIn(
                                        arrayHelpers.form.touched,
                                        `deadlines[${index}].date`,
                                      ) &&
                                      !!getIn(
                                        arrayHelpers.form.errors,
                                        `deadlines[${index}].date`,
                                      ),
                                    helperText:
                                      !!getIn(
                                        arrayHelpers.form.touched,
                                        `deadlines[${index}].date`,
                                      ) &&
                                      getIn(
                                        arrayHelpers.form.errors,
                                        `deadlines[${index}].date`,
                                      ),
                                  },
                                }}
                                onChange={(value) => {
                                  formik.setFieldValue(
                                    `deadlines[${index}].date`,
                                    dayjs(value),
                                  );
                                }}
                                value={deadline.date}
                                label="Data terminu"
                              />
                              {/*<FormTextField fieldName={`deadlines[${index}].date`}*/}
                              {/*               label={"Data terminu"}/>*/}
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                    <Grid item xs={6}>
                      <Paper
                        variant="outlined"
                        sx={{
                          mt: 2,
                          p: 3.25,
                          mx: -1,
                          borderStyle: "dashed",
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                        square={true}
                      >
                        <Button
                          onClick={() =>
                            arrayHelpers.push({ name: "", date: null })
                          }
                          variant="text"
                          startIcon={<AddIcon />}
                        >
                          Nowy termin
                        </Button>
                      </Paper>
                    </Grid>
                  </React.Fragment>
                )}
              />

              {/*<Grid item xs={6}>*/}
              {/*    <Typography component={'h3'}>Załączniki</Typography>*/}
              {/*</Grid>*/}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Anuluj</Button>
            <LoadingButton loading={isLoading || isUpdating} type={"submit"}>
              {itemToEdit ? "Zapisz" : "Dodaj"}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
}
