import * as React from "react";
import { useEffect, useState } from "react";
import { Avatar, Card, CardContent, List, ListItemAvatar } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Skeleton } from "@mui/lab";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BuildIcon from "@mui/icons-material/Build";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../constants/date";
import { ServiceLogItem, useDeleteServiceLog } from "../../../../api/fleet";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LoadingButton from "@mui/lab/LoadingButton";
import AddServiceLog from "../AddServiceLog";
import { useAppState } from "../../../../contexts/AppState";

interface Props {
  fleetItemId: string;
  serviceLog: ServiceLogItem[];
  showLoader: boolean;
}

const listItem = { mx: 0, px: 0 };
const ServiceLogList = ({ fleetItemId, serviceLog, showLoader }: Props) => {
  const [isAddServiceModalOpened, setAddServiceModalOpened] =
    useState<boolean>(false);

  const [serviceLogToEdit, setServiceLogToEdit] = useState<ServiceLogItem | null>(null)
  const { setSnackbar } = useAppState();
  const {
    mutate: deleteServiceLog,
    isSuccess: serviceLogDeleted,
    isLoading: serviceLogDeleting,
    isError: serviceLogDeleteError,
  } = useDeleteServiceLog();
  const onAddServiceLogButtonClick = () => {
    setAddServiceModalOpened(true);
  };
  const onServiceLogEdit = (serviceLogItem: ServiceLogItem) => {
    setAddServiceModalOpened(true);
    setServiceLogToEdit(serviceLogItem);
  };

  const onModalClose = () => {
    setAddServiceModalOpened(false);
    setServiceLogToEdit(null);
  }
  const onServiceLogDelete = (serviceLogItemId: number) => {
    deleteServiceLog(serviceLogItemId);
  };

  useEffect(() => {
    if (serviceLogDeleted) {
      setSnackbar({
        type: "success",
        message: "Plik został usunięty!",
      });
    }
  }, [serviceLogDeleted, setSnackbar]);

  useEffect(() => {
    if (serviceLogDeleteError) {
      setSnackbar({
        type: "error",
        message: "Wystąpił błąd...",
      });
    }
  }, [serviceLogDeleteError, setSnackbar]);

  return (
    <Card sx={{ mt: 2 }}>
      {isAddServiceModalOpened && (
        <AddServiceLog
          open={isAddServiceModalOpened}
          fleetItemId={Number(fleetItemId)}
          onClose={onModalClose}
          serviceLog={serviceLogToEdit}
        />
      )}
      <CardContent>
        <Grid container alignItems={"center"}>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
              Książka serwisowa
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <LoadingButton
              loading={showLoader}
              size={"small"}
              color={"primary"}
              startIcon={<AutoStoriesIcon />}
              onClick={onAddServiceLogButtonClick}
            >
              Dodaj wpis
            </LoadingButton>
          </Grid>
        </Grid>
        <List>
          {showLoader &&
            Array.from(Array(7).keys()).map((i) => (
              <Skeleton key={i} height={60} />
            ))}
          {!showLoader &&
            serviceLog.map((serviceLogItem) => (
              <ListItem
                key={serviceLogItem.id}
                sx={listItem}
                secondaryAction={
                  <>
                    <IconButton
                      edge="end"
                      aria-label="Delete"
                      onClick={() => onServiceLogEdit(serviceLogItem)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={serviceLogDeleting}
                      onClick={() => onServiceLogDelete(serviceLogItem.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <BuildIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${dayjs(serviceLogItem.serviceDate).format(
                    DATE_FORMAT,
                  )} - ${serviceLogItem.type} - ${serviceLogItem.mileage}km`}
                  secondary={serviceLogItem.description}
                />
              </ListItem>
            ))}
        </List>

        {!serviceLog.length && (
          <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
            Brak wpisów
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ServiceLogList;
