import * as React from "react";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import pl from "@fullcalendar/core/locales/pl";
import { Card, CardContent, GlobalStyles, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "wouter";
import { useGetDeadlines } from "../../../../api/deadline";
import { Skeleton } from "@mui/lab";

function renderEventContent(eventInfo: any) {
  return (
    <>
      <Link
        to={`/fleet/${eventInfo.event.id}`}
        style={{ color: "white", textDecoration: "none" }}
      >
        {eventInfo.event.title}
      </Link>
    </>
  );
}

export default function EventsList() {
  const { data, isLoading } = useGetDeadlines();
  const parsedEvents = (data || []).map((event) => ({
    title: `${event.name} - ${event.fleetItem.make} ${event.fleetItem.model} - ${event.fleetItem.registrationNumber}`,
    start: event.date,
    allDay: true,
    id: `${event.fleetItemId}`,
  }));
  const theme = useTheme();
  return (
    <>
      <GlobalStyles
        styles={{
          ":root": {
            "--fc-border-color": theme.palette.grey.A700,
            "--fc-today-bg-color": "rgba(124, 179, 66, 0.16)",
            "--fc-neutral-bg-color": "transparent",
            "--fc-page-bg-color": "transparent",
            "--fc-list-event-hover-bg-color": "transparent",
            "--fc-button-bg-color": theme.palette.primary.main,
            "--fc-button-hover-bg-color": theme.palette.primary.dark,
          },
          ".events-list": {
            ".fc .fc-toolbar-title": {
              fontSize: "16px",
            },
            ".fc table": {
              fontSize: theme.typography.fontSize,
            },
            ".fc .fc-toolbar.fc-header-toolbar": {
              marginBottom: "15px",
            },
          },
        }}
      />
      <div className={"events-list"}>
        <Card>
          <CardContent>
            <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
              Lista terminów
            </Typography>
            {isLoading &&
              Array.from(Array(10).keys()).map((i) => (
                <Skeleton key={i} height={40} />
              ))}
            {!isLoading && (
              <FullCalendar
                events={parsedEvents}
                initialView={"listWeek"}
                plugins={[listPlugin]}
                locale={pl}
                eventBackgroundColor={theme.palette.primary.main}
                displayEventTime={false}
                eventContent={renderEventContent}
                eventBorderColor={theme.palette.primary.main}
              />
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
}
