import axios from "axios";
import { GET_HISTORY_URL } from "../constants/urls";
import { useQuery } from "@tanstack/react-query";

export interface HistoryItem {
  createdAt: Date;
  createdBy: {
    firstName: string;
    lastName: string;
  };
  fleetItem: {
    make: string;
    model: string;
    registrationNumber: string;
    id: number;
  };
  description: string;
}

export interface HistoryLogRequest {
  take: number;
}

export interface History {
  data: HistoryItem[];
}

const fetchHistory = (take: number) =>
  axios.get<History>(`${GET_HISTORY_URL}?take=${take}`).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

export const useHistoryLog = (take: number) => {
  return useQuery(["fetchHistory", take], () => fetchHistory(take), {
    select: ({ data }) => data.data,
  });
};
