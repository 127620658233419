import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { Link, useLocation } from "wouter";
import { useTheme } from "@mui/material";

interface Route {
  id: string;
  icon: React.ReactNode;
  link?: string;
  disabled?: boolean;
}

interface Routes {
  id: string;
  children: Route[];
}

export const routes: Routes[] = [
  {
    id: "main",
    children: [{ id: "Dashboard", icon: <HomeIcon />, link: "/" }],
  },
  {
    id: "primary",
    children: [
      { id: "Flota", icon: <DirectionsCarIcon />, link: "/fleet" },
      { id: "Terminarz", icon: <CalendarMonthIcon />, link: "/calendar" },
      { id: "Użytkownicy", icon: <PeopleAltIcon />, link: "/users" },
      {
        id: "Załączniki",
        icon: <AttachFileIcon />,
        link: "/files",
        disabled: true,
      },
    ],
  },
  {
    id: "secondary",
    children: [
      {
        id: "Ustawienia",
        icon: <SettingsIcon />,
        link: "/settings",
        disabled: true,
      },
      { id: "Pomoc", icon: <HelpOutlineIcon />, link: "/help", disabled: true },
      {
        id: "Zgłoś błąd",
        icon: <FeedbackIcon />,
        link: "/feedback",
        disabled: true,
      },
      { id: "Wyloguj", icon: <LogoutIcon />, disabled: false },
    ],
  },
];

const item = {
  py: 0.75,
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

interface Props extends DrawerProps {
  onLogOut: () => void;
}

export default function Navigator(props: Props) {
  const { onLogOut, ...other } = props;
  const theme = useTheme();
  const [location] = useLocation();
  const [homeRoutes, ...otherRoutes] = routes;
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{
            ...item,
            ...itemCategory,
            fontSize: 22,
            color: "#fff",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          {"Loggy - flota"}
        </ListItem>
        {homeRoutes.children.map(({ id, icon, link }) => (
          <ListItem disablePadding key={link + id}>
            <Link href={link || ""}>
              <ListItemButton
                sx={{ ...item, ...itemCategory }}
                selected={link === location}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{id}</ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        {otherRoutes.map(({ id, children }) => (
          <Box key={id}>
            {children.map(({ id: childId, icon, link, disabled }) => (
              <ListItem disablePadding key={childId}>
                {link && (
                  <Link href={link}>
                    <ListItemButton
                      sx={item}
                      selected={link === location}
                      disabled={disabled}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{childId}</ListItemText>
                    </ListItemButton>
                  </Link>
                )}
                {childId === "Wyloguj" && (
                  <ListItemButton sx={item} onClick={onLogOut}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                )}
              </ListItem>
            ))}
            <Divider />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
