import * as yup from "yup";

export const validationSchema = yup.object({
  serviceDate: yup.string().min(3, "Nieprawidłowa data").required("Wymagane"), // these constraints take precedence
  mileage: yup
      .number()
      .typeError('Przebieg musi być liczbą')
      .required('Wymagane'),
  type: yup
    .string()
    .min(2, "Typ serwisu może składać się z minimum 2 znaków")
    .max(25, "Typ serwisu może składać się z maximum 25 znaków")
    .required("Wymagany"),
  description: yup.string().optional(),
});
