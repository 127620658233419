import axios, { AxiosError } from "axios";
import {
  ADD_FLEET_URL,
  GET_FLEET_URL,
  ADD_SERVICE_LOG_URL,
  DELETE_SERVICE_LOG_URL,
  UPDATE_SERVICE_LOG_URL
} from "../constants/urls";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export interface Attachment {
  description?: string;
  createdBy: {
    firstName: string;
    lastName: string;
  };
  createdAt: Date;
  name: string;
  key: string;
}

export interface FleetItem {
  id: number;
  registrationNumber: string;
  make: string;
  model: string;
  year?: number;
  mileage?: number;
  additionalInfo?: string;
  vinNumber?: string;
  deadlines: Deadline[];
  drivers: {
    driver: Driver;
  }[];
  historyLog?: {
    description: string;
    createdAt: Date;
    createdBy: {
      firstName: string;
      lastName: string;
    };
  }[];
  attachments: Attachment[];
  serviceLog: ServiceLogItem[];
}

export interface Driver {
  id?: number;
  name: string;
  phoneNumber: string;
}

export interface Deadline {
  id?: number;
  name: string;
  date: Date;
}

export interface ServiceLogItem {
  id: number;
  mileage: number;
  description?: string;
  serviceDate: Date;
  type: string;
}

export interface FleetData {
  data: FleetItem[];
}

export interface FleetItemData {
  data: FleetItem;
}

export interface AddFleetItemRequest {
  registrationNumber?: string;
  make?: string;
  model?: string;
  year?: number;
  mileage?: number;
  additionalInfo?: string;
  vinNumber?: string;
  deadlines?: Deadline[];
  drivers?: Driver[];
}

export interface AddServiceLogRequest {
  fleetItemId: number;
  type: string;
  description?: string;
  serviceDate: Date;
  mileage: number;
}

export interface UpdateFleetItemRequest {
  data: AddFleetItemRequest;
  id: number;
}

export interface UpdateServiceLogItemRequest {
  data: AddServiceLogRequest;
  id: number;
}

const fetchFleet = () =>
  axios.get<FleetData>(GET_FLEET_URL).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

const addFleetItem = (fleetItemData: AddFleetItemRequest) =>
  axios.post<FleetData>(ADD_FLEET_URL, fleetItemData).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

const updateFleetItem = ({ id, data }: UpdateFleetItemRequest) =>
  axios.patch<FleetData>(`${ADD_FLEET_URL}/${id}`, data).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

const deleteFleetItem = (fleetId: number) =>
  axios.delete(`${ADD_FLEET_URL}/${fleetId}`).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

const getFleetItem = (itemId: number) =>
  axios.get<FleetItemData>(`${ADD_FLEET_URL}/${itemId}`).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

const addServiceLog = (serviceLogData: AddServiceLogRequest) =>
    axios.post<ServiceLogItem>(ADD_SERVICE_LOG_URL, serviceLogData).catch((e) => {
      if (e.response && e.response.data.message) {
        e.message = e.response.data.message;
      }
      throw e;
    });
const deleteServiceLog = (serviceLogId: number) =>
    axios.delete(`${DELETE_SERVICE_LOG_URL}/${serviceLogId}`).catch((e) => {
      if (e.response && e.response.data.message) {
        e.message = e.response.data.message;
      }
      throw e;
    })

const updateServiceLog = ({ id, data }: UpdateServiceLogItemRequest) =>
    axios.patch<FleetData>(`${UPDATE_SERVICE_LOG_URL}/${id}`, data).catch((e) => {
      if (e.response && e.response.data.message) {
        e.message = e.response.data.message;
      }
      throw e;
    });
export const useFleet = () => {
  return useQuery(["fetchFleet"], fetchFleet, {
    select: ({ data }) => data.data,
  });
};

export const useGetFleetItem = (fleetId: number) => {
  return useQuery(["fetchFleetItem", fleetId], () => getFleetItem(fleetId), {
    select: ({ data }) => data.data,
  });
};

export const useAddServiceLog = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, AddServiceLogRequest>({
    mutationFn: addServiceLog,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};

export const useDeleteServiceLog = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, any>({
    mutationFn: deleteServiceLog,
    onSuccess: ({data}) => {
      queryClient.invalidateQueries({ queryKey: ["fetchFleetItem", data.data.fleetItemId] });
    },
  });
};

export const useEditServiceLog = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, UpdateServiceLogItemRequest>({
    mutationFn: updateServiceLog,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};

export const useAddFleet = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, AddFleetItemRequest>({
    mutationFn: addFleetItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchFleet"] })
    },
  });
};

export const useEditFleet = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, UpdateFleetItemRequest>({
    mutationFn: updateFleetItem,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};

export const useDeleteFleetItem = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, any>({
    mutationFn: deleteFleetItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchFleet"] });
    },
  });
};

/*
export function useSignUp() {
    return useMutation<SigninResponse, AxiosError, SigninData>({
        mutationFn: signIn,
    })
}
 */
