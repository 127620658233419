export const HOST =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : "https://loggy-fleet-api-95ccee308a92.herokuapp.com";

export const GET_USERS_URL = `${HOST}/users`;
export const SIGNIN_URL = `${HOST}/users/signin`;
export const SIGNUP_URL = `${HOST}/users/signup`;
export const GET_FLEET_URL = `${HOST}/fleet`;
export const ADD_FLEET_URL = `${HOST}/fleet`;

export const ADD_SERVICE_LOG_URL = `${HOST}/service`
export const DELETE_SERVICE_LOG_URL = `${HOST}/service`
export const UPDATE_SERVICE_LOG_URL = `${HOST}/service`

export const EXTEND_DEADLINE_URL = `${HOST}/deadlines`;
export const GET_HISTORY_URL = `${HOST}/history`;
export const FILE_UPLOAD_URL = `${HOST}/attachment`;
export const FILE_DOWNLOAD_URL = `${HOST}/attachment`;
export const FILE_LIST_URL = `${HOST}/attachment`;
