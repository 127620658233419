import { FILE_UPLOAD_URL } from "../constants/urls";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface Attachment {
  id: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  createdAt: Date;
  createdBy: string;
  url: string;
}

export interface AttachmentData {
  id: number;
  createdAt: Date;
  size: number;
  name: string;
  createdBy: {
    firstName: string;
    lastName: string;
  };
  fleetItem: {
    registrationNumber: string;
    make: string;
    model: string;
    id: number;
  };
  key: string;
  description: string | null;
}

interface AttachmentResponse {
  data: AttachmentData[];
}

interface UploadAttachmentResponse {
  data: {
    data: string;
  };
}

const uploadFile = (formData: FormData) =>
  axios
    .post(FILE_UPLOAD_URL, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .catch((e) => {
      if (e.response && e.response.data.message) {
        e.message = e.response.data.message;
      }
      throw e;
    });

const deleteFile = (fileName: string) =>
  axios.delete(`${FILE_UPLOAD_URL}/${fileName}`).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

export const downloadFile = (fileName: string) =>
  axios.get(`${FILE_UPLOAD_URL}/${fileName}`).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

export const getAllFiles = () =>
  axios.get<AttachmentResponse>(`${FILE_UPLOAD_URL}`).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

export function useUploadAttachment() {
  const client = useQueryClient();
  return useMutation<UploadAttachmentResponse, AxiosError, FormData>({
    mutationFn: uploadFile,
    onSuccess: () => client.invalidateQueries(),
  });
}

export function useDeleteFile() {
  const client = useQueryClient();
  return useMutation<UploadAttachmentResponse, AxiosError, string>({
    mutationFn: deleteFile,
    onSuccess: () => client.invalidateQueries(),
  });
}

export const useDownloadAttachment = (fileName: string) => {
  return useQuery(["downloadFile", fileName], () => downloadFile(fileName), {
    select: ({ data }) => data,
    enabled: !!fileName,
  });
};

export const useGetAttachments = () => {
  return useQuery(["attachmentsList"], getAllFiles, {
    select: ({ data }) =>
      data.data.map((a) => ({
        ...a,
        createdAt: new Date(a.createdAt),
      })),
  });
};

export const attachmentsMock = {
  data: [
    {
      id: "21",
      description: "Attachment 21",
      fileName: "attachment21.docx",
      fileSize: 3072,
      fileType: "docx",
      createdAt: new Date(),
      createdBy: "User C",
      url: "https://example.com/attachments/21",
    },
    {
      id: "22",
      description: "Attachment 22",
      fileName: "attachment22.png",
      fileSize: 4096,
      fileType: "png",
      createdAt: new Date(),
      createdBy: "User D",
      url: "https://example.com/attachments/22",
    },
    {
      id: "23",
      description: "Attachment 23",
      fileName: "attachment23.txt",
      fileSize: 512,
      fileType: "txt",
      createdAt: new Date(),
      createdBy: "User E",
      url: "https://example.com/attachments/23",
    },
    {
      id: "24",
      description: "Attachment 24",
      fileName: "attachment24.xlsx",
      fileSize: 8192,
      fileType: "xlsx",
      createdAt: new Date(),
      createdBy: "User F",
      url: "https://example.com/attachments/24",
    },
    {
      id: "25",
      description: "Attachment 25",
      fileName: "attachment25.jpg",
      fileSize: 10240,
      fileType: "jpg",
      createdAt: new Date(),
      createdBy: "User G",
      url: "https://example.com/attachments/25",
    },
    {
      id: "26",
      description: "Attachment 26",
      fileName: "attachment26.pdf",
      fileSize: 20480,
      fileType: "pdf",
      createdAt: new Date(),
      createdBy: "User H",
      url: "https://example.com/attachments/26",
    },
    {
      id: "27",
      description: "Attachment 27",
      fileName: "attachment27.png",
      fileSize: 30720,
      fileType: "png",
      createdAt: new Date(),
      createdBy: "User I",
      url: "https://example.com/attachments/27",
    },
    {
      id: "28",
      description: "Attachment 28",
      fileName: "attachment28.docx",
      fileSize: 40960,
      fileType: "docx",
      createdAt: new Date(),
      createdBy: "User J",
      url: "https://example.com/attachments/28",
    },
    {
      id: "29",
      description: "Attachment 29",
      fileName: "attachment29.txt",
      fileSize: 51200,
      fileType: "txt",
      createdAt: new Date(),
      createdBy: "User K",
      url: "https://example.com/attachments/29",
    },
    {
      id: "30",
      description: "Attachment 30",
      fileName: "attachment30.xlsx",
      fileSize: 61440,
      fileType: "xlsx",
      createdAt: new Date(),
      createdBy: "User L",
      url: "https://example.com/attachments/30",
    },
    {
      id: "31",
      description: "Attachment 31",
      fileName: "attachment31.pptx",
      fileSize: 71680,
      fileType: "pptx",
      createdAt: new Date(),
      createdBy: "User M",
      url: "https://example.com/attachments/31",
    },
    {
      id: "32",
      description: "Attachment 32",
      fileName: "attachment32.jpg",
      fileSize: 81920,
      fileType: "jpg",
      createdAt: new Date(),
      createdBy: "User N",
      url: "https://example.com/attachments/32",
    },
    {
      id: "33",
      description: "Attachment 33",
      fileName: "attachment33.pdf",
      fileSize: 92160,
      fileType: "pdf",
      createdAt: new Date(),
      createdBy: "User O",
      url: "https://example.com/attachments/33",
    },
    {
      id: "34",
      description: "Attachment 34",
      fileName: "attachment34.txt",
      fileSize: 102400,
      fileType: "txt",
      createdAt: new Date(),
      createdBy: "User P",
      url: "https://example.com/attachments/34",
    },
    {
      id: "35",
      description: "Attachment 35",
      fileName: "attachment35.xlsx",
      fileSize: 112640,
      fileType: "xlsx",
      createdAt: new Date(),
      createdBy: "User Q",
      url: "https://example.com/attachments/35",
    },
    {
      id: "36",
      description: "Attachment 36",
      fileName: "attachment36.png",
      fileSize: 122880,
      fileType: "png",
      createdAt: new Date(),
      createdBy: "User R",
      url: "https://example.com/attachments/36",
    },
    {
      id: "37",
      description: "Attachment 37",
      fileName: "attachment37.docx",
      fileSize: 133120,
      fileType: "docx",
      createdAt: new Date(),
      createdBy: "User S",
      url: "https://example.com/attachments/37",
    },
    {
      id: "38",
      description: "Attachment 38",
      fileName: "attachment38.jpg",
      fileSize: 143360,
      fileType: "jpg",
      createdAt: new Date(),
      createdBy: "User T",
      url: "https://example.com/attachments/38",
    },
    {
      id: "39",
      description: "Attachment 39",
      fileName: "attachment39.pdf",
      fileSize: 153600,
      fileType: "pdf",
      createdAt: new Date(),
      createdBy: "User U",
      url: "https://example.com/attachments/39",
    },
    {
      id: "40",
      description: "Attachment 40",
      fileName: "attachment40.xlsx",
      fileSize: 163840,
      fileType: "xlsx",
      createdAt: new Date(),
      createdBy: "User V",
      url: "https://example.com/attachments/40",
    },
  ],
};
