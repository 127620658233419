import axios, { AxiosError } from "axios";
import { SIGNIN_URL } from "../constants/urls";
import { useMutation } from "@tanstack/react-query";

interface SigninData {
  email: string;
  password: string;
}

interface SigninResponse {
  data: {
    data: string;
  };
}

const signIn = (signinData: SigninData) =>
  axios.post(SIGNIN_URL, signinData).catch((e) => {
    if (e.response && e.response.data.message) {
      e.message = e.response.data.message;
    }
    throw e;
  });

export function useSignUp() {
  return useMutation<SigninResponse, AxiosError, SigninData>({
    mutationFn: signIn,
  });
}
